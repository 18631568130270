<template>
	<div class="popup active">
        <div class="notice_popup prj_itv state">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
            </div>
            <div class="tit mb-12">프로젝트 참여소속 승인요청</div>

            <div class="board">
                <table class="Board_type2">
                    <colgroup>
                        <col width="300">
                        <col width="*">
                    </colgroup>
                    <!-- <tbody v-if="this.param.projectJoinCorpSeq"> -->
                    <tbody v-if="this.param.corpSeq">
                        <tr>
                            <th>기존 프로젝트 참여소속사</th>
                            <td>{{this.param.projectJoinCorpNm}}</td>
                        </tr>
                        <tr>
                            <th>
                               변경할 프로젝트 참여소속사
                            </th>
                            <td>
								<AutoComplete type="text" v-model="changeCorpNm" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo"  title="회사명" :rules="{'required':true}">
									<template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
								</AutoComplete>
                            </td>
                        </tr>
                    </tbody>
					<tbody v-else>
                        <tr>
                            <th>
                               프로젝트 참여소속사
                            </th>
                            <td>
								<AutoComplete type="text" v-model="changeCorpNm" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo"  title="회사명" :rules="{'required':true}">
									<template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
								</AutoComplete>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="ta-center pt-8 pb-4 mt-8" style="font-size: 19px; border-top: 1px solid #58595B;">
                위 정보로 프로젝트 참여소속 승인요청을 하시겠습니까?
            </div>

            <div class="select-btn">
                <div class="btn" @click="$emit('close')">취소</div>
                <div class="btn" @click="insertCorpSeq()">예</div>
            </div>

			<div class="notice_box"> 
				<p><strong>※ 소속사 입력 시 유의사항</strong></p>
				<p>1. 기술인재의 프로젝트 참여 소속사가 본인의 인력공급 계약주체로서 계약을 수요기업과 체결하게 됩니다.</p>
				<p>2. 따라서 기술인재의 소속사가 변경될 경우 시스템에서 자동으로 전소속사 및 신규 소속사에<br/><nbsp n="4"/>소속 변경요청 사실을 통보하고, 각 사에 승인을 요청합니다.</p>
				<!-- <p><nbsp n="4"/>소속 변경요청 사실을 통보하고, 각 사에 승인을 요청합니다.</p> -->
				<p>3. 소속사 변경 요청에 앞서 기술인재가 소속사와 직접 합의한 후 진행하도록 권고드립니다.</p>
			</div>

        </div>
    </div>
</template>

<script>
export default {
	props: {
		param : Object,
	},
	
	data() {
		return {
			data : this.param,
			changeCorpSeq : '',
			changeCorpNm : '',
			corpList : [],
		}
	},

	mounted() {
		//console.log('param', this.param);
		this.corpSearch('20');
	},

	methods : {
		corpSearch(div) {
            var param = {
                corpDivCd : div,
                pageType : 'cham',
            }
			this.$.httpPost('/api/mem/searchCorp', param, '')
				.then(res => {
					if(res.data.resultCode == '000') {
						this.corpList = res.data.corpList;
					}
				});
		},

		setCorpInfo(corpInfo) {
			if(corpInfo) {
				this.changeCorpSeq = corpInfo.corpSeq;
				this.changeCorpNm = corpInfo.corpNm;
			}else {
				this.changeCorpSeq = '';
				this.changeCorpNm = '';
			}
		},

        insertCorpSeq() {
            //console.log(this.data);
            //console.log(this.changeCorpNm);
            //console.log(this.changeCorpSeq);

			if(this.data.projectJoinCorpSeq && (this.changeCorpSeq == null || this.changeCorpSeq == '')) {
				alert("변경할 소속사를 선택해주세요!");
			} else {
				var paramInfo = {
                    classDivCd : '03',
                    mberSeq : this.data.tecSeq,
                    preCorpSeq : this.data.projectJoinCorpSeq == undefined ? null : this.data.projectJoinCorpSeq,
                    aftCorpSeq : this.changeCorpSeq,
                    apprCorpList : [],
				}
                if(paramInfo.preCorpSeq != null) {
                    paramInfo.apprCorpList.push(paramInfo.preCorpSeq);
                }

                paramInfo.apprCorpList.push(this.changeCorpSeq);

                var param = {
                    mberInfo : paramInfo,
                }

				this.$.httpPost('/api/mem/prf/insertAsgChangeCorp', param)
					.then(() => {
						alert('변경요청되었습니다.');
						this.$emit('close');
						this.$router.go(0);
					}) 
			}
        },
	}
}
</script>
